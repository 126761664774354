@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: rgb(241, 241, 241);
}
.rotate {
    animation: rotateClock 1s infinite linear;
  }
  
  @keyframes rotateClock {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

/* Personalização da barra de rolagem para webkit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 10px; /* Largura da barra de rolagem */
 }
 
 ::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor de fundo da barra de rolagem */
 }
 
 ::-webkit-scrollbar-thumb {
  background: rgb(102, 102, 239); /* Cor do thumb da barra de rolagem */
  border: solid 1px #f1f1f1; /* Borda do thumb */
  border-radius: 100vh; /* Arredondamento do thumb */
  transition: background-color 0.3s ease; /* Transição suave para mudanças de cor */
 }
 
/*  ::-webkit-scrollbar-thumb:hover {
  background: rgb(153, 153, 255);
 }
  */

 
  